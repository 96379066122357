.home-container {
  padding: 0 !important;
  .section-hero {
    overflow: hidden;
    // min-height: 97vh;
    display: flex;
    align-items: center;
    position: relative;
    // background-image: url("../../assets/imgs/background.jpg");
    // background-repeat: no-repeat;
    // background-size: cover;
    // background-position: center;
    // background-image: url("../../assets/imgs/bg-marketplace-1.svg");
    // background-size: 147vh;
    // background-position: right 15% bottom -18vh;
    // margin-top: -89px;
    background-color: var(--black);
    padding-bottom: 40px;

    .container {
      width: 100%;
      max-width: 1680px;
      padding-right: 15px;
      padding-left: 15px;
      margin-right: auto;
      margin-left: auto;
    }
    @media screen and (max-width: 1600px) {
      .container {
        max-width: 1440px;
      }
    }

    @media screen and (max-width: 680px) {
      background-size: cover;
      background-position: center;
    }
    @media screen and (max-width: 576px) {
      margin-top: 0;
      background-position: center;
      .container {
        width: 100%;
      }
      h4 {
        font-size: 14px;
      }
    }

    &__wrapper {
      display: flex;
      overflow: hidden;
      flex-wrap: wrap;
      margin-top: 0;
      box-shadow: 0 7px 29px 0 rgba(0, 0, 0, 0.07);
      border: 1px solid rgba(255, 255, 255, 0.3);
      border-radius: 8px;
      position: relative;

      @media screen and (max-width: 1200px) {
        border: 0;
        box-shadow: none;
        border-radius: 0;
      }
    }

    &__video-wrapper {
      width: 25%;

      display: flex;
      border-radius: 8px 0px 0px 8px;
      position: relative;

      @media screen and (max-width: 1200px) {
        width: 100%;
        margin: auto;
        border-radius: 0;
      }

      .section-hero__video {
        position: relative;
        width: 100%;

        &:before {
          padding-top: 150%;
          display: block;
          content: "";
        }

        &:after {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          content: "";
          opacity: 0.9;
          background-image: linear-gradient(45deg, #000, transparent);
        }

        @media screen and (max-width: 1200px) {
          width: 53%;
          margin: auto;
          border-radius: 0;
        }
      }

      video {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
        border-radius: 8px 0px 0px 8px;
      }
    }

    &__content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      max-width: 440px;

      @media screen and (max-width: 1200px) {
        max-width: none;
      }

      width: 100%;
      margin: auto;
    }

    &__body {
      display: flex;
      width: 75%;
      padding-left: 12px;
      position: relative;

      @media screen and (max-width: 1200px) {
        width: 100%;
        padding-left: 0;
        margin-top: 40px;
      }

      .xsigma-nft-marketplace-2 {
        color: var(--white);
        line-height: 1.1;
        margin-top: 0px;

        span {
          position: relative;
          &:after {
            content: "";
            bottom: -1px;
            left: 3px;
            position: absolute;
            width: calc(100% - 6px);
            height: 2px;
            background: #fff;
          }
        }
      }
      .upcoming-title {
        color: var(--white);
        font-weight: 400;
        opacity: 1;
        margin-left: 0px;
      }

      .btn {
        width: fit-content;
        margin-top: 20px;
        padding-left: 26px;
        padding-right: 26px;
      }
    }

    &__img-right {
      position: absolute;
      right: 0;
      top: 0;
      height: 100%;

      @media screen and (max-width: 1200px) {
        display: none;
      }

      &--mobile {
        display: none;
        position: absolute;
        right: 0;
        top: 0;
        height: 100%;

        @media screen and (max-width: 1200px) {
          display: block;
        }
      }
    }

    &__img-left {
      position: absolute;
      left: 12px;
      top: 0;
      height: 100%;

      @media screen and (max-width: 1200px) {
        display: none;
      }

      &--mobile {
        display: none;
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;

        @media screen and (max-width: 1200px) {
          display: block;
        }
      }
    }
  }
  .item-columns {
    @media screen and (max-width: 693px) {
      .auction-item {
        margin-right: 0 !important;
        max-width: 100%;
        min-width: 100%;
      }
    }
  }

  .xsigma-nft-marketplace-2 {
    text-transform: uppercase;
    font-size: 40px;
    line-height: 0.9;
    margin-top: 140px;

    @media screen and (max-width: 1200px) {
      margin-top: 130px;
    }
    @media screen and (max-width: 680px) {
      margin-top: 120px;
    }
    @media screen and (max-width: 580px) {
      margin-top: 80px;
      font-size: 40px;
    }
  }

  .xsigma-nft-marketplace {
    line-height: 1;
    font-size: 8.3vh;
    margin-top: 60px;
    span {
      background: linear-gradient(90deg, #1ac3f0, #ed489c);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      display: inline-block;
    }
    @media screen and (max-width: 1200px) {
      margin-top: 130px;
    }
    @media screen and (max-width: 680px) {
      margin-top: 120px;
    }
    @media screen and (max-width: 580px) {
      margin-top: 80px;
      font-size: 40px;
    }
  }
  .sub-txt {
    font-size: 1.25rem;
    margin-bottom: 8.96vh;
  }
  .tags {
    .tags-wrapper {
      display: flex;
      align-items: center;
      margin: 0;
      padding: 0;
      border: 0;
      flex-wrap: wrap;
      .tags-wrapper__item {
        margin-right: 8px;
        list-style: none;
        margin-bottom: 10px;
        .tags-wrapper__item-tag {
          cursor: pointer;
          color: var(--black);
          font-size: 1.5rem;
          font-family: "Montserrat", sans-serif;
          font-weight: bold;
          text-transform: uppercase;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 9px;
          width: 140px;
          height: 45px;
          vertical-align: baseline;
          border: 2px solid black;
          box-sizing: border-box;
          text-decoration: none;
          -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
          &:hover {
            border-color: rgba(black, 0.6);
          }
        }
      }
    }
  }
  .full-width {
    position: relative;
    z-index: 2;
    width: 100%;
    line-height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #dddddd;
  }
  .divide-section {
    display: flex;
    justify-content: center;
    height: 152px;
    position: relative;
    &:after {
      content: "";
      position: absolute;
      top: 50%;
      width: 100%;
      height: 1px;
      background-color: rgba(0, 0, 0, 0.1);
    }
    .left {
      width: 50%;
      padding-right: 140px;
      text-align: right;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      border-right: 1px solid rgba(0, 0, 0, 0.1);
      img {
        width: 147px;
        height: 42px;
      }
    }
    .right {
      display: flex;
      align-items: center;
      width: 50%;
      padding-left: 140px;
      img {
        width: 270px;
        height: 42px;
      }
    }
    @media screen and (max-width: 992px) {
      .left {
        padding-right: 70px;
      }
      .right {
        padding-left: 70px;
      }
    }
    @media screen and (max-width: 768px) {
      .left {
        padding-right: 35px;
      }
      .right {
        padding-left: 35px;
      }
    }
    @media screen and (max-width: 768px) {
      height: 76px;
      .left img {
        width: 74px;
        height: auto;
      }
      .right img {
        width: 135px;
        height: auto;
      }
    }
  }
  .home-screen-img {
    margin-top: -130px;
    width: 100%;
    height: 100%;
    object-fit: cover;
    padding-right: 40px;
    @media screen and (max-width: 1400px) {
      margin-top: -50px;
    }
    @media screen and (max-width: 992px) {
      padding-right: 150px;
    }
    @media screen and (max-width: 640px) {
      padding-right: 100px;
    }
    @media screen and (max-width: 475px) {
      padding-right: 0px;
    }
  }
  .top-sellers {
    margin-top: 80px;
    .user-filter {
      font-family: "Matrice";
      font-weight: bold;
      font-size: 32px;
      line-height: 32px;
      padding-bottom: 12px;
      align-items: center;
      background-image: linear-gradient(
          to left,
          transparent 0%,
          cyan 0,
          transparent 0
        ),
        linear-gradient(101deg, var(--black), var(--black));
      background-size: 100% 2px;
      background-position: 0% 0%, 0% 100%;
      background-repeat: no-repeat;
      @media screen and (max-width: 640px) {
        font-size: 10px;
      }
      .dropdown-toggle {
        border: none;
        outline: none;
        box-shadow: inset 0 0px 0 #ddd !important;
        font-weight: bold;
        font-size: 22px;
        width: auto;
        &:after {
          -webkit-text-fill-color: var(--black);
          font-size: 12px;
          margin-left: 10px;
        }
        @media screen and (max-width: 640px) {
          font-size: 10px;
        }
      }
      .dropdown-item {
        font-size: 14px;
        font-family: "Montserrat";
        div {
          line-height: 1.2 !important;
        }
      }
    }
  }
  .fa-check {
    color: var(--black);
  }
  .category-list {
    display: flex;
    flex-wrap: wrap;
    margin-right: 1.5rem !important;
    @media screen and (max-width: 768px) {
      margin-right: 0 !important;
    }
  }
  h2 {
    text-transform: uppercase;
    font-family: "Matrice";
    line-height: 30px;
    @media screen and (max-width: 640px) {
      font-size: 20px;
    }
  }

  .upcoming-title {
    font-size: 13px;
    color: var(--black);
    font-family: "Matrice-Regular";
    opacity: 0.3;
    margin-left: 10px;
  }

  .upcoming-auctions {
    margin-right: -15px;
    @media screen and (max-width: 1200px) {
      margin-left: -15px;
      margin-bottom: 0;
    }
    .carousel {
      .carousel-indicators {
        bottom: -40px;
        margin-left: 10px;
        justify-content: left;
        li {
          background-color: var(--white);
          opacity: 1;
          border-top: 0px solid transparent;
          border-bottom: 0px solid transparent;
          width: 50px;
          height: 6px;
          border-radius: 6px;
          margin-right: 5px;
          &.active {
            background-color: rgba(white, 0.5);
          }
        }
      }
      .carousel-control-prev,
      .carousel-control-next {
        display: none;
      }
      .upcoming-auction-item {
        width: 50%;
        height: 280px;
        position: relative;
        margin: 0;
        cursor: pointer;
        &:after {
          content: "";
          position: absolute;
          width: 100%;
          height: 100%;
          left: 0;
          top: 0;
          background: linear-gradient(144deg, black, transparent);
          z-index: 0;
          border-radius: 0;
          opacity: 0.8;
        }
        @media screen and (max-width: 680px) {
          width: 100%;
        }
        img {
          width: 100%;
          height: 280px;
          object-fit: cover;
          border-radius: 0;
          position: relative;
        }
        .name {
          position: absolute;
          top: 20px;
          left: 20px;
          color: var(--white);
          font-family: "Matrice";
          font-size: 1.3vw;
          padding-right: 20px;
          z-index: 2;
          @media screen and (max-width: 992px) {
            font-size: 18px;
          }
          span {
            line-height: 1;
            font-family: "Montserrat";
            font-size: 12px;
          }
        }
        .btn {
          background-color: rgba(0, 0, 0, 0.3);
          backdrop-filter: saturate(180%) blur(20px);
          border: 1px solid transparent;
          width: 150px;
          position: absolute;
          bottom: 10px;
          right: 10px;
          font-family: "Matrice";
          font-size: 12px;
          display: flex;
          align-items: center;
          line-height: 0.9;
          white-space: nowrap;
          padding: 16.5px;
          border-radius: 5px !important;
          justify-content: center;
          z-index: 1;
          text-transform: uppercase;
          &:hover {
            background-color: rgba(black, 0.6);
          }
        }
      }
    }
  }
  .hero-text {
    width: 75%;
    @media screen and (max-width: 1200px) {
      width: 100%;
    }
  }
}
