.token-detail {
  display: flex;
  flex-direction: column;
  background: var(--black);
  color: var(--white);

  &__title {
    font-size: 36px;
    line-height: 1;
  }

  .media {
    margin-top: 69px;
    border-radius: 10px;
    width: 100%;
  }

  .royalties-description {
    background: radial-gradient(
      115.34% 575.98% at 104.79% 0%,
      rgba(113, 227, 242, 0.17) 0%,
      rgba(124, 177, 255, 0.17) 44.96%,
      rgba(250, 0, 255, 0.09) 100%
    );
    text-align: center;
    padding: 12px 22px;
    border-radius: 30px;
    color: var(--black);
  }

  &__shared-dropdown {
    .dropdown-toggle {
      &:after {
        display: none !important;
      }
    }

    .dropdown-menu__content {
      margin-top: 10px;
      border: 1px solid rgba(255, 255, 255, 0.1);
      background: rgba(0, 0, 0, 0.1);
      display: flex;
      flex-direction: column;
      text-align: left;

      .dropdown-item {
        padding: 0.75rem 1.5rem;
        color: #fff;
        text-align: left;
      }

      >:not(:first-child, :last-child) .dropdown-item {
        border-top: 1px solid rgba(255, 255, 255, 0.1);
        border-bottom: 1px solid rgba(255, 255, 255, 0.1);
      }
    }
  }

  &__dropdown {
    .dropdown-toggle {
      &:after {
        display: none !important;
      }
    }

    .dropdown-menu {
      background: #000;
      color: #fff;
      padding: 20px;

      &.show {
        display: flex;
      }

      .dropdown-item {
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;

        &:first-child {
          border-radius: 20px 0 0 20px;
          border: 1px solid #fff;
        }

        &:last-child {
          border-radius: 0 20px 20px 0;
          margin-left: -1px;
          border: 1px solid #fff;
        }
      }
    }
  }

  .list {
    overflow-y: auto;
    max-height: 420px;
    .item-detail {
      padding-right: 5px;
      @media screen and (max-width: 1380px) {
        width: 75%;
      }
      @media screen and (max-width: 1200px) {
        width: 70%;
      }
      @media screen and (max-width: 768px) {
        width: 80%;
      }
      @media screen and (max-width: 475px) {
        width: 70%;
      }

      .grey-color {
        font-weight: 500;
      }
    }
  }

  .liked-action,
  .shared-action,
  .collection-detail__btn,
  .token-detail__categories-item {
    cursor: pointer;
    padding: 0.5em 1.25em 0.5em 1em;
    display: flex;
    align-items: center;
    font-size: 16px;
    color: var(--white);
    font-family: "Matrice-Regular";
    line-height: 1;
    min-height: 2.75em;
    border: 1px solid rgba(255, 255, 255, 0.1);
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out;
    text-transform: uppercase;

    span {
      margin-bottom: -0.3em;
    }

    svg,
    img {
      font-size: 1.625em;
      height: 1em;
      width: 1em;
      color: var(--white);
      margin-right: 0.4em;
    }

    img {
      padding: 1.5px;
    }

    &:hover,
    &:focus {
      background: var(--white);
      border-color: var(--white);
      color: var(--black);

      svg {
        color: var(--black);
      }
    }
  }

  .token-detail__categories-item {
    padding: 8px 20px 8px 20px;
  }

  & .liked-action,
  & .shared-action {
    font-size: 11px;
  }

  & .collection {
    position: relative;
    border-radius: 15px;
    padding: 10px 30px;
    margin-top: 10px;
    overflow: hidden;

    @media screen and (max-width: 576px) {
      padding: 5px 10px;
    }

    &:hover,
    &:focus {
      .collection-overlay img {
        transform: scale(1.1);
      }
    }

    &-overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;

      &:after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.4);
      }

      img {
        transition: transform 0.3s cubic-bezier(0.23, 1, 0.32, 1);
        object-fit: cover;
        height: 100%;
        width: 100%;
      }
    }

    &-detail {
      &__btn {
        cursor: auto;
        z-index: 2;
        margin: 0.625em 0;
        font-size: 11px;

        &:hover,
        &:focus {
          background: transparent;
          border: 1px solid rgba(255, 255, 255, 0.1);
          color: var(--white);

          svg {
            color: var(--white);
          }
        }
      }

      &__right {
        z-index: 2;
        padding: 1.25em;
        font-size: 11px;
        backdrop-filter: blur(20px);
        border-radius: 1.25em;
        margin: 0.625em 0 0.625em 0.625em;
        display: flex;
        flex-direction: column;
      }

      &__title {
        margin-top: auto;
        display: flex;
        align-items: center;
        font-size: 11px;
        color: var(--white);
        font-family: "Matrice-Regular";
        text-transform: uppercase;
        line-height: 1;

        img {
          margin-left: 0;
          margin-right: 10px;
          margin-top: -3px;
        }
      }

      &__available {
        font-size: 11px;
        margin-bottom: 10px;
        color: var(--white);
        font-family: "Matrice";
        text-transform: uppercase;
      }
    }
  }

  .auction {
    position: relative;
    border-radius: 15px;
    padding: 20px 30px;
    margin-top: 10px;
    border: 1px solid rgba(255, 255, 255, 0.1);

    @media screen and (max-width: 576px) {
      padding: 10px;
    }

    > .row:last-child:not(:first-child) {
      margin-top: 40px;
      position: relative;

      &:before {
        content: "";
        background: rgba(255, 255, 255, 0.1);
        width: calc(100% + 30px);
        height: 1px;
        position: absolute;
        top: -20px;
        left: -15px;
      }
    }

    &--with-buttons {
      border-radius: 15px 15px 0 0;
    }

    &-bid,
    &-buy {
      position: relative;
      display: flex;
      align-items: center;
      align-self: baseline;
      justify-content: center;
      line-height: 1;
      padding: 15px 20px 12px 20px;

      @media screen and (max-width: 576px) {
        padding: 10px 10px 8px 10px;
        font-size: 9px;
      }

      @media screen and (max-width: 360px) {
        padding: 10px 10px 8px 10px;
        font-size: 8px;
      }

      span {
        white-space: nowrap;
      }

      &__price {
        display: flex;
        align-items: center;
        font-family: "Matrice-Regular";
        bottom: 0px;
        font-size: 10px;
        font-weight: 400;
        margin-top: 5px;
        white-space: nowrap;

        @media screen and (max-width: 576px) {
          font-size: 9px;
        }

        @media screen and (max-width: 360px) {
          font-size: 8px;
        }
      }

      &__token {
        height: 1em;
        margin-top: -0.3em;
        margin-left: 0.5em;
        margin-right: 0.2em;

        &--white {
          display: none;
        }
      }

      &:hover,
      &:focus {
        .auction-buy__token--white,
        .auction-bid__token--white {
          display: block;
        }

        .auction-buy__token--dark,
        .auction-bid__token--dark {
          display: none;
        }
      }
    }

    &-bid {
      &.btn-black {
        .auction-bid__token {
          &--white {
            display: block;
          }
          &--dark {
            display: none;
          }
        }
      }
    }

    &-icons {
      display: flex;
      align-items: center;

      img {
        height: 20px;

        @media screen and (max-width: 576px) {
          height: 10px;
        }
      }

      &__visa {
        height: 50px;
        margin-left: 10px;
        width: auto;

        @media screen and (max-width: 576px) {
          height: 25px;
          margin-left: 5px;
        }
      }

      &__mastercard {
        height: 35px;
        fill: gray;
        stroke: gray;
        stroke-width: 0px;
        margin-left: 10px;
        width: auto;

        @media screen and (max-width: 576px) {
          height: 17.5px;
          margin-left: 5px;
        }
      }
    }

    &-status {
      font-family: "Matrice-Regular";
      font-weight: 400;
      text-transform: uppercase;
      white-space: nowrap;
    }

    &-timer {
      display: flex;

      > div {
        min-width: 40px;
      }

      &__label {
        font-family: "Matrice-Regular";
        font-weight: 400;
      }

      &__time {
        font-family: "Matrice-Regular";
        font-weight: 400;
      }
    }
  }

  .tab-nav {
    border-bottom: 1px solid #ccc;
    overflow-x: auto;

    &__item {
      padding-bottom: 10px;
      background-image: none;
      background-size: 100% 4px;
      background-position: 0% 0%, 0% 100%;
      background-repeat: no-repeat;
      font-size: 11px;
      font-weight: 300;
      text-transform: uppercase;
      font-family: "Matrice";

      &--active {
        background-image: linear-gradient(
            to left,
            transparent 0%,
            cyan 0,
            transparent 0
          ),
          linear-gradient(101deg, var(--white), var(--white));
      }
    }
  }

  .view-scan {
    align-items: flex-end;
    span {
      color: rgba(grey, 0.7);
      margin-right: 5px;
    }
    a {
      white-space: nowrap;
    }
  }

  .sold-owner {
    border-radius: 10px;
    // background-color: var(--black);
    color: var(--black);
    padding-top: 15px;
    padding-bottom: 10px;
  }

  .special-buttons {
    margin: -15px;

    @media screen and (max-width: 576px) {
      margin: -7px;
    }

    @media screen and (max-width: 360px) {
      margin: -5px;
    }

    > div {
      padding: 15px;

      @media screen and (max-width: 576px) {
        padding: 7px;
      }

      @media screen and (max-width: 360px) {
        padding: 5px;
      }
    }

    .place-btn {
      margin-right: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 2px !important;
      color: var(--white);
      box-shadow: 0 0 6px 0 rgba(157, 96, 212, 0.5);
      border: solid 3px transparent;
      background-image: linear-gradient(
          rgba(255, 255, 255, 0),
          rgba(255, 255, 255, 0)
        ),
        linear-gradient(101deg, #ffca3d, #ed489c, #1ac3f0);
      background-origin: border-box;
      background-clip: content-box, border-box;
      box-shadow: 2px 1000px 1px var(--white) inset;
      padding: 10px 12.5px;
      white-space: nowrap;
      &:hover {
        box-shadow: 2px 1000px 1px var(--black) inset !important;
        color: white !important;
      }
      &:active {
        box-shadow: 2px 1000px 1px var(--white) inset !important;
      }
      width: 100%;
      &.no-direct {
        margin-right: 0;
      }
    }

    @media screen and (max-width: 1400px) {
      flex-wrap: wrap;
      .place-btn {
        margin-right: 0px;
      }
    }
    @media screen and (max-width: 991px) {
      .place-btn {
        margin-right: 20px;
        &.no-direct {
          margin-right: 0;
        }
      }
    }
    @media screen and (max-width: 564px) {
      flex-wrap: wrap;
      .place-btn {
        margin-right: 0;
      }
    }
  }

  .featured-media {
    position: relative;

    .cover-image {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: calc(100% - 80px);
      object-fit: cover;
      margin-top: 0;
    }

    .loading-bar {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      &-cover {
        top: calc(50% - 40px);
      }
    }
  }

  .loading-bar {
    span {
      border: 6px solid var(--white);
      border-top-color: transparent;
    }
  }

  &__details {
    border: 1px solid rgba(255, 255, 255, 0.1);
    padding: 15px;
    display: flex;
    border-radius: 8px;

    @media screen and (max-width: 576px) {
      flex-wrap: wrap;
    }

    &-col {
      flex: 0 0 40%;
      display: flex;

      @media screen and (max-width: 576px) {
        flex: 1 0 100%;
        margin-bottom: 10px;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    &-divider {
      flex: 1 0 auto;
      position: relative;

      @media screen and (max-width: 576px) {
        display: none;
      }

      &:after {
        content: "";
        position: absolute;
        left: calc(50% - 1px);
        height: 100%;
        width: 1px;
        background: rgba(255, 255, 255, 0.2);
      }
    }

    &-item {
      display: flex;
      flex-direction: column;
    }

    &-title {
      font-size: 13px;
      color: var(--white);
      margin-bottom: 10px;
      opacity: 0.8;
      margin-right: 20px;
      white-space: nowrap;

      @media screen and (max-width: 576px) {
        min-width: 120px;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }

    &-text {
      font-size: 13px;
      color: var(--white);
      text-align: left;
      margin-bottom: 10px;

      &:last-child {
        margin-bottom: 0;
      }

      a {
        color: var(--white) !important;
      }
    }
  }

  &__price {
    flex-shrink: 0;

    &-col {
      display: flex;
      flex-direction: column;
      margin-left: 10px;
    }

    &-text {
      font-size: 40px;
      font-weight: 300;
      line-height: 0.7;
      margin-bottom: 15px;
    }

    &-subtext {
      font-weight: 300;
      font-size: 18px;
      opacity: 0.3;
      line-height: 0.7;
      text-align: right;
    }
  }

  &__creator {
    display: flex;

    &-image {
      width: 40px !important;
      height: 40px !important;
    }

    &-text {
      display: flex;
      text-transform: uppercase;
      color: var(--white);
      align-items: center;

      img {
        margin-top: -3px;
      }
    }
  }

  &__col {
    display: flex;

    img {
      height: 28px;
    }
  }

  &__bid {
    &-subtext {
      font-size: 8px;
      margin-top: 2px;
      line-height: 1;
      font-weight: 700;
    }
  }

  &__tabs-item {
    font-size: 12px;
  }
}
