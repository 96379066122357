.file-uploader {
  text-align: center;
  border: 2px dashed #d5d5d5;
  box-sizing: border-box;
  border-radius: 2px;
  .preview {
    position: relative;
    .close-btn {
      position: absolute;
      right: 0px;
      top: -10px;
      svg {
        cursor: pointer;
        font-size: 24px;
      }
    }
    img {
      max-width:100%;
      width: 320px;
      margin: 10px auto;
    }
  }
}
