.activity-item {
  border-radius: 16px;
  padding: 24px;
  border: 2px solid #0000002e;
  display: flex;
  align-items: center;
  cursor: pointer;
  @media screen and (max-width: 360px) {
    flex-wrap: wrap;
    .featured-image {
      margin: 0 auto 10px auto;
    }
  }
  &:hover {
    border: 2px solid transparent;
    background-image: var(--black);
    background-origin: border-box;
    background-clip: content-box, border-box;
    box-shadow: 2px 1000px 2px var(--white) inset;
  }
  .featured-image {
    position: relative;
    img {
      width: 68px;
      height: 68px;
    }
    .badge {
      position: absolute;
      left: -10px;
      top: -10px;
      width: 26px;
      height: 26px;
      line-height: 26px;
      border-radius: 26px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0;
      svg {
        font-size: 14px;
        color: var(--white);
      }
    }
  }
  .content {
    margin-left: 16px;
    .title {
      cursor: pointer;
      .nav-link {
        padding: 0;
        color: var(--black);
        line-height: 1;
      }
    }
    .details {
      .detail-info {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        span {
          color: rgb(128, 128, 128);
          margin-right: 10px;
          font-size: 10px;
          white-space: nowrap;
        }
        .small-featured {
          img {
            width: 16px;
            height: 16px;
            border-radius: 16px;
          }
          display: flex;
          align-items: center;
        }
      }
      .type {
        color:rgb(128, 128, 128);
      }
    }
    .date {
      font-size: 10px;
      color: rgb(128, 128, 128);
    }
  }
}