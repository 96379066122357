.activity-container {
  .link-section {
    border-bottom: 1px solid #BEBEBE;
    .nav {
      flex-wrap: nowrap;
      overflow-x: auto;
      .nav-link {
        white-space: nowrap;
      }
    }
    .nav-link.active {
      background-image: linear-gradient(to left, transparent 0%, cyan 0, transparent 0), linear-gradient(101deg, var(--black));
      background-size: 100% 2px;
      background-position: 0% 0%, 0% 100%;
      background-repeat: no-repeat;
    }
  }
  .link-section a {
    border-bottom: 1px solid rgba(255,255,255,0.3);
    color: var(--black) !important;
  }
  
  .divider {
    margin-top: 14px;
    opacity: 0.2;
    border-top: 2px solid black;
    margin-left: -30px;
    margin-right: -30px;
  }
  
  .activity-list, .tab-content, .tab-pane {
    height: 100%;
  }
  .activity-item {
    margin-bottom: 20px;
  }
}