.create-nft-status-modal {
  .modal-dialog {
    top: 10%;
    .modal-content {
      max-width: 440px;
      border-radius: 2rem;
      .mint-alone {
        .spinner-border {
          width: 3rem;
          height: 3rem;
        }
      }

      .btn {
        width: 100%;
        display: block!important;
        padding: 8px 24px;
        border-radius: 20px !important;
        &.outline-btn {
          padding: 0;
        }
        span {
          border-radius: 16px;
        }
      }
      .fa-check {
        font-size: 26px;
        color: grey;
      }
    }
  }
}
