.profile-container {
  .back-link {
    display: flex;
    align-items: flex-start;
    color: var(--black);
    opacity: 0.4;
    div {
      font-family: "Matrice";
    }
  }
  .row-reserve {
    @media screen and (max-width: 768px) {
      flex-wrap: wrap-reverse;
    }
    .avatar-uploader {
      .avatar-preview {
        margin: 20px auto;
        width: 106px;
        height: 106px;
        border-radius: 106px;
        border: 3px solid black;
        position: relative;
        .remove-avatar {
          color: red;
          position: absolute;
          right: 0;
          top: 0;
        }
        img {
          width: 100px;
          height: 100px;
          border-radius: 100px;
          object-fit: cover;
        }
      }
      .file-uploader {
        padding: 20px 30px !important;
      }
    }
  }
  .form-control {
    border-radius: 20px;
    height: 54px;
    &:hover {
      border-color: rgba(#191919, 0.8);
    }
  }
  .form-group {
    position: relative;
    span {
      position: absolute;
      left: 15px;
      bottom: 15px;
      color: var(--black);
    }
    svg {
      position: absolute;
      right: 20px;
      bottom: 20px;
      color: #ff48fa;
    }
  }
}