.collectible-container {

  .create-collectible-txt {
    margin-top: 100px;
    span {
      background: linear-gradient(90deg, #1AC3F0, #ED489C, #FFCA3D);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      display: inline-block;
    }
    @media screen and (max-width: 1400px) {
      font-size: 48px;
      margin-top: 40px;
    }
    @media screen and (max-width: 1200px) {
      font-size: 43px;
      margin-top: 40px;
    }
    @media screen and (max-width: 640px) {
      font-size: 30px;
      margin-top: 40px;
    }
  }
  h4 {
    font-size: 16px;
    line-height: 25px;
    font-weight: 500;
    opacity: 0.4;
  }
  .collectible-img {
    max-width: 90%;
  }
  .star-image {
    max-width: 92px;
  }
  p {
    font-size: 18px;
    line-height: 23px;
    color: #c4c4c4;
  }
  .row-reserve {
    @media screen and (max-width: 992px) {
      flex-wrap: wrap-reverse;
    }
  }
}
