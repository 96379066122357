.buy-token-modal {
  .modal-dialog {
    .modal-content {
      background-color: #000;
      color: #fff;
      border: 1px solid rgba(255, 255, 255, 0.1);
    }

    .modal-header {
      border-bottom: 1px solid rgba(255, 255, 255, 0.1);
      padding: 2rem 2rem 1.3rem;

      .close {
        color: #fff;
        text-shadow: none;
        &:focus,
        &:hover {
          opacity: 1;
        }
      }
    }

    .modal-body {
      padding: 2rem;

      .btn-link {
        margin-top: 1rem;
        color: #fff;
      }
    }
  }

  .form-control {
    color: #fff;
    &:hover,
    &:focus {
      border-color: #fff;
      box-shadow: none;
      color: #fff;
      background-color: #000;
    }
  }

  .buy-token-modal__divider {
    background: rgba(255, 255, 255, 0.1);
  }
}
