.collection-detail-container {
  padding: 0px !important;
  display: flex;
  flex-direction: column;

  .collection-detail {
    &__head {
      background: var(--black);
      display: flex;
      height: 100%;

      @media screen and (max-width: 1200px) {
        flex-wrap: wrap;
      }
    }

    &__timer {
      &-wrapper {
        display: flex;
        align-items: flex-start;
        color: var(--white);
        margin-top: 30px;
        flex-wrap: wrap;

        .dropdown-toggle:after {
          display: none;
        }

        .dropdown-menu {
          background: var(--white);
          padding: 15px;
        }

        .form-control {
          height: 49px;
          margin-right: 20px;
        }
      }

      &-title {
        align-self: flex-start;
        font-size: 12px;
        font-family: "Matrice-Regular";
        text-transform: uppercase;
        color: var(--white);
        line-height: 1;
        margin-right: 30px;
        margin-bottom: 20px;
      }

      &-label {
        font-family: "Matrice-Regular";
        font-size: 12px;
        margin-right: 30px;
        text-transform: uppercase;
        margin-bottom: 20px;
      }

      &-time {
        line-height: 1;
        margin-bottom: 10px;
        font-size: 20px;
        text-transform: uppercase;
        font-family: "Matrice-Regular";
      }
    }

    &__btn {
      padding: 0.5em 1.25em 0.5em 1em;
      display: flex;
      align-items: center;
      font-size: 11px;
      color: var(--white);
      font-family: "Matrice-Regular";
      line-height: 1;
      min-height: 2.75em;
      border: 1px solid rgba(255, 255, 255, 0.1);
      transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out;
      text-transform: uppercase;
      margin-right: 20px;
      margin-bottom: 10px;

      &-wrapper {
        display: flex;
        margin-top: 30px;
        flex-wrap: wrap;
      }

      span {
        margin-bottom: -0.3em;
      }

      img {
        padding: 1.5px;
      }

      svg,
      img {
        font-size: 1.625em;
        height: 1em;
        width: 1em;
        color: var(--white);
        margin-right: 0.4em;
      }
    }

    &__intro {
      max-width: 500px;
      flex-grow: 1;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      overflow: hidden;
      position: relative;

      &:after {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        background: linear-gradient(15deg, black, transparent);
        z-index: 0;
        border-radius: 0;
        opacity: 0.8;
      }

      @media screen and (max-width: 1200px) {
        max-width: 100%;
        flex-basis: 100%;
        height: 300px;
      }
    }

    &__body {
      padding-left: 50px;
      max-width: 75%;
      flex-basis: 0;
      flex-grow: 1;
      max-width: 900px;
      padding-bottom: 50px;

      @media screen and (max-width: 1200px) {
        padding: 40px 30px;
        max-width: 100%;
        flex-basis: 100%;
      }
    }

    &__status {
      color: var(--white);
      position: absolute;
      font-size: 10px;
      text-transform: uppercase;
      top: -35px;
      left: 0;
    }

    &__avatar {
      width: 200px;
      height: 200px;
      object-fit: cover;
      border-radius: 10px;
    }

    &__description {
      color: var(--white);
      margin-top: 10px;
      font-size: 11px;
    }

    &__title {
      color: var(--white);
      font-family: "Matrice";
      font-weight: 700;
      font-size: 36px;
      line-height: 36px;
      margin-top: 40px;
      word-break: break-word;
      position: relative;

      .verify-badge {
        height: 20px;
        margin-top: 6px;
        margin-right: 15px;
        float: left;
      }
    }

    &__tokens {
      padding-top: 10px;

      .loading-bar {
        height: 420px;
      }

      .link-section {
        .nav {
          justify-content: center;

          &-item {
            &.disabled {
              pointer-events: none;
            }
          }
          &-link {
            text-transform: uppercase;
            font-family: "Matrice-Regular";
            font-size: 11px;

            span {
              padding: 3px 5px 1px;
              font-size: 11px;
            }

            &.active {
              font-family: "Matrice";
              span {
                font-family: "Matrice-Regular";
              }
            }
          }
        }
      }

      .tokens-section,
      .collections-section,
      .auction-section,
      .royalties-section {
        margin-top: 1.5rem;
      }

      .token-view,
      .auction-item,
      .collection-item {
        margin-bottom: 1.5rem;
        @media screen and (max-width: 693px) {
          max-width: 100%;
          min-width: 100%;
          margin-right: 0 !important;
        }
      }

      .notice-view {
        margin-top: 80px;
        @media screen and (max-width: 768px) {
          margin-top: 40px;
        }
        .notice {
          max-width: 910px;
        }
        .btn-section {
          margin-top: 50px;
        }
      }

      .royal-section {
        margin-top: 74px;
        max-width: 500px;
        width: 100%;
        .border-bottom {
          border-bottom: 1px solid #afafaf !important;
          padding-bottom: 10px;
        }
      }

      .question-section {
        margin-top: 150px;
      }
    }
  }

  .section-1 {
    .upcoming {
      position: absolute;
      left: 50%;
      bottom: -70px;
      transform: translate(-50%, 100%);
      display: flex;
      align-items: center;
      justify-content: center;
      line-height: 30px;
    }
    .intro {
      position: relative;
      width: 100%;
      height: 252px;
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      -webkit-background-size: cover;
      -moz-background-size: cover;
      -o-background-size: cover;
      padding: 15px 30px;
      box-shadow: -10px -1px 5px 0px rgba(255, 255, 255, 75%);
      -webkit-box-shadow: -10px -1px 5px 0px rgba(255, 255, 255, 75%);
      -moz-box-shadow: -10px -1px 5px 0px rgba(255, 255, 255, 75%);
      padding: 15px 30px;
      @media screen and (max-width: 786px) {
        padding: 15px 20px;
      }
      .avatar {
        width: 120px;
        height: 120px;
        border: 5px solid var(--white);
        position: absolute;
        left: 50%;
        bottom: 0px;
        transform: translate(-50%, 50%);
        object-fit: cover;
      }
      .verify-badge {
        width: 24px;
        height: 24px;
        position: absolute;
        bottom: -54px;
        left: 53%;
        @media screen and (max-width: 991px) {
          left: 55%;
        }
      }
    }
  }

  .section-2 {
    margin-top: 104px;
    .title {
      font-family: Montserrat;
      font-weight: 700;
      font-size: 30px;
      line-height: 50px;
      text-align: center;
      padding: 0 1rem;
      @media screen and (max-width: 576px) {
        font-size: 28px;
        line-height: 28px;
      }
    }
  }

  .form-control {
    border: 0;
    border-bottom: 2px solid var(--black);
    border-radius: 0;

    &:hover,
    &:focus,
    &:active {
      outline: none;
      box-shadow: none;
      border-color: var(--black);
    }
  }

  .section-3 {
    margin-top: 24px;
  }

  .no-data {
    .no-item {
      max-width: 350px;
    }
  }
  .date-time {
    font-size: 38px;
  }
}

.collection__details {
  display: flex;
  align-items: center;
  z-index: 2;
  margin-top: 20px;

  .collection-item & {
    position: relative;
    margin-top: 15px;

    &:before {
      content: "";
      position: absolute;
      width: calc(100% + 3rem);
      left: -1.5rem;
      height: 1px;
      top: 0;
      background: var(--white);
      opacity: 0.2;
    }
  }

  &-container {
    display: flex;

    .collection-item & {
      border-radius: 15px;
      margin-left: auto;
      margin-right: auto;
      border-radius: 0;
      box-shadow: none;
      background: transparent;
    }
  }

  &-item {
    padding: 15px;

    .collection-detail__head & {
      padding: 0;
      margin-right: 30px;
    }

    .collection-item & {
      padding: 0;
      margin-right: 30px;
      margin-top: 15px;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  &-title {
    color: rgba(255, 255, 255, 0.6);
    font-size: 12px;
    text-align: center;
    font-weight: 500;

    .collection-detail__head & {
      text-align: left;
      font-size: 11px;
      text-transform: uppercase;
      font-weight: 400;
      font-family: "Matrice-Regular";
    }
  }

  &-value {
    display: flex;
    justify-content: center;
    font-weight: bold;
    font-size: 12px;

    .collection-item & {
      color: var(--white);
    }

    .collection-detail__head & {
      margin-top: 5px;
      font-size: 16px;
      color: var(--white);
      text-align: left;
      justify-content: flex-start;
      align-items: center;
      font-family: "Matrice-Regular";

      img {
        margin-top: -4px;
        margin-right: 8px;
        width: auto;
        height: 20px;
      }
    }

    img {
      margin-right: 5px;
    }
  }
}
