.tokenization-container {
  overflow-x: hidden;
  .title-txt {
    margin-top: 100px;
    span {
      background: linear-gradient(90deg, var(--black),var(--black));
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      display: inline-block;
    }
    @media screen and (max-width: 1400px) {
      font-size: 48px;
      margin-top: 40px;
    }
    @media screen and (max-width: 1200px) {
      font-size: 43px;
      margin-top: 40px;
    }
    @media screen and (max-width: 640px) {
      font-size: 30px;
      margin-top: 40px;
    }
  }
  h4 {
    font-size: 16px;
    line-height: 25px;
    font-weight: 500;
    opacity: 0.4;
  }
  .collectible-img {
    max-width: 90%;
  }
  .row-reserve {
    @media screen and (max-width: 992px) {
      flex-wrap: wrap-reverse;
    }
  }
}
