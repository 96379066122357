.search-container {
  padding: 0 !important;
}
.search-view {
  left: 0px;
  top: 89px;
  width: 100%;
  z-index: 111;
  padding: 40px 20px 0 60px;
  @media screen and (max-width: 1200px) {
    padding: 40px 20px 0 30px;
  }
  @media screen and (max-width: 992px) {
    padding: 40px 20px 0 30px;
  }
  @media screen and (max-width: 768px) {
    padding: 40px 20px 0 20px;
  }

  .close-search-view {
    cursor: pointer;
    font-size: 20px;
    font-weight: normal;
    color: var(--black);
  }
  .search-view-input {
    border: none;
    border-bottom: 1px solid #ededed;
    box-sizing: border-box;
    padding: 8px 16px;
    svg {
      color: var(--black);
      opacity: 0.5;
      font-size: 20px;
    }
    .form-control {
      background: transparent;
      border: none;
      outline: none;
      font-size: 26px;
      padding: 0 0.75rem;
      box-shadow: inset 0 0px 0 #ddd !important;
      @media screen and (max-width: 992px) {
        font-size: 22px;
      }
      @media screen and (max-width: 768px) {
        font-size: 18px;
      }
      @media screen and (max-width: 640px) {
        font-size: 14px;
      }
      &:focus {
        background: transparent;
      }
    }
  }
  .show-content {
    width: 100%;
    min-height: calc(100vh - 290px);
    .no-data {
      max-width: 350px;
      color: var(--black);
    }
    .collection-item,
    .auction-item {
      @media screen and (max-width: 654px) {
        max-width: 100%;
        margin-right: 0 !important;
        width: 100%;
      }
    }
  }

  .filters-view {
    display: none;
    @media screen and (max-width: 640px) {
      display: block;
      font-size: 22px;
      color: var(--black);
      cursor: pointer;
      margin-left: auto;
      display: flex;
      align-items: center;
    }
  }

  &-btn {
    position: fixed;
    z-index: 2;
    bottom: 0;
    left: -100%;
    width: 100%;
    height: 75px;
    background: var(--black);
    color: var(--white);
    text-transform: uppercase;
    transition: ease-in-out left 0.5s;
    cursor: pointer;
    display: flex;
    font-family: "Matrice";
    align-items: center;
    justify-content: center;

    &.show {
      left: 0;
    }

    @media screen and (min-width: 641px) {
      display: none;
    }
  }

  .search-view-filter {
    min-width: 260px;
    max-width: 260px;
    color: var(--black);
    box-sizing: border-box;
    backdrop-filter: blur(10px);
    background-color: var(--white);

    > div {
      &:first-child {
        margin-top: 0 !important;
      }
    }

    &:hover {
      border-color: rgba(#aaaaaa, 0.3);
    }

    @media screen and (max-width: 640px) {
      position: fixed;
      left: -100%;
      right: 0;
      width: 100%;
      box-sizing: border-box;
      max-width: none;
      min-height: auto;
      height: calc(100% - 255px);
      top: 180px;
      overflow-y: auto;
      overflow-x: hidden;
      padding-left: 20px;
      z-index: 2;
      transition: ease-in-out left 0.5s;

      scrollbar-color: #9e9fa0 transparent;
      scrollbar-width: thin;

      &::-webkit-scrollbar {
        width: 5px;
        height: 5px;
      }

      /* Track */
      &::-webkit-scrollbar-track {
        background: transparent;
      }

      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: #9e9fa0;
        border-radius: 3px;
      }

      /* Handle on hover */
      &::-webkit-scrollbar-thumb:hover {
        background: #9e9fa0;
      }

      &.show {
        left: 0;
      }
    }
    .fa-check {
      color: var(--black);
    }
    .price-range {
      max-width: 200px;
    }
    .category-type-btn {
      display: inline-block;
      cursor: pointer;
      border: 1px solid #6b66664a;
      &:hover {
        border-color: var(--black);
      }
      padding: 5px 11px;
      font-size: 12px;
      box-sizing: border-box;
      border-radius: 40px;
      text-align: center;
      font-weight: 500;
      min-width: 42px;
    }
    .category-type-selected {
      border-color: var(--black);
      background: var(--white) !important;
    }
    .faint-color {
      font-family: "Matrice";
      text-transform: uppercase;
    }
    input[type="date"]::-webkit-calendar-picker-indicator {
      cursor: pointer;
      border-radius: 4px;
      margin-right: 2px;
      opacity: 1;
      filter: invert(0.8);
    }

    input[type="date"]::-webkit-calendar-picker-indicator:hover {
      opacity: 0.6;
    }
  }
  @media screen and (max-width: 640px) {
    .search-view-filter.show + .mobile-space {
      display: block;
      position: absolute;
      right: 0;
      width: 100%;
      min-height: 130vh;
    }
  }
  .search-content {
    width: 100%;
    // margin: 0 auto;
    @media screen and (max-width: 856px) {
      width: calc(100% - 280px);
    }
    @media screen and (max-width: 640px) {
      width: 100%;
    }
    .loading-bar-center {
      height: 100%;
    }
    .item-col {
      @media screen and (max-width: 640px) {
        width: 100%;
        max-width: 100%;
        margin-right: 0 !important;
      }
    }
  }

  .auction-item,
  .collection-item {
    max-width: 270px;
    min-width: 270px;
    @media screen and (max-width: 902px) {
      max-width: 300px;
      min-width: 300px;
    }
  }
}
