.collection-item,
.auction-item {
  cursor: pointer;
  overflow: hidden;
  box-sizing: border-box;
  display: flex;
  z-index: 1;
  position: relative;
  flex-direction: column;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.06);
  border-radius: 16px;
  min-width: 300px;
  max-width: 300px;
  margin-bottom: 1.5rem;
  transition: all 300ms cubic-bezier(0.23, 1, 0.32, 1);
  -webkit-transform:translateZ(0);
  transform:translateZ(0);

  &:hover {
    transform: translateY(-4px) translateZ(0);
  }

  .wallet-address {
    min-height: 19px;
  }

  .token-details-area {
    flex-grow: 1;

    &:before {
      content: "";
      position: absolute;
      left: -1.5rem;
      bottom: -1.5rem;
      background: linear-gradient(transparent, rgba(0, 0, 0, 0.9));
      border-bottom-left-radius: 16px;
      border-bottom-right-radius: 16px;
      width: calc(100% + 3rem);
      height: calc(100% + 3rem);
      z-index: -1;
    }

    z-index: 2;
    color: var(--white);
    position: relative;

    .likes {
      color: var(--white);
      height: 44px;
    }
    a {
      color: var(--white) !important;
    }

    .grey-color {
      color: rgba(255, 255, 255, 0.6) !important;
      font-weight: 500;
    }
  }

  .token-img-area {
    height: 240px;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    z-index: 1;
    .pre-token-img {
      margin: 0px;
      padding: 0px;
      -webkit-box-align: stretch;
      align-items: stretch;
      border-width: 0px;
      border-style: solid;
      border-color: rgb(4, 4, 5);
      display: flex;
      flex-basis: auto;
      flex-direction: column;
      flex-shrink: 0;
      min-width: 0px;
      width: 100%;
      height: 100%;

      img {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        border: 0;
      }
    }
  }
  .no-thumbnail {
    width: 100%;
    height: 100%;
  }
  .auction-owner-avatar {
    width: 30px;
    height: 30px;
  }
}

.collection-item {
  color: var(--black);
  position: relative;

  &:hover,
  &:focus {
    color: var(--black);
  }

  &:hover,
  &:focus {
    .collection-item-bg {
      img {
        transform: scale(1.1);
      }
    }
  }

  .collection-item-bg {
    height: 300px;

    img {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      right: 0;
      border-radius: 16px;
      bottom: 0;
      left: 0;
      object-fit: cover;
      transition: transform 0.3s cubic-bezier(0.23, 1, 0.32, 1);
    }

    .no-thumbnail {
      max-width: 100%;
      min-height: 300px;
    }
  }

  .item-content {
    z-index: 2;

    .grey-color {
      color: rgba(255, 255, 255, 0.6) !important;
      font-weight: 500;
    }
  }

  .nft-avatar {
    position: absolute;
    top: -50px;
    left: calc(50% - 30px);
    z-index: 2;
  }
}

.choose-collection-item {
  width: 160px;
  min-width: 160px;
  border: 2px solid #c4c4c4;
  box-sizing: border-box;
  border-radius: 16px;
  cursor: pointer;
  .faint-color {
    white-space: pre-line;
    word-break: break-all;
    line-height: 1;
  }
  &.selected {
    border-color: #136bfb !important;
  }
  &.dark-collection-item {
    border: 2px solid #1f1f1f;
  }
  .c-nft-avatar {
    width: 62px;
    height: 62px;
    text-align: center;
    font-size: 18px;
    margin-left: auto;
    margin-right: auto;
    background: #c4c4c4;
    border-radius: 50%;
    img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
    }
  }
  .add-collection-btn {
    line-height: 62px;
  }
}

.collection-item {
  .title {
    color: var(--white);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-family: "Matrice" !important;
  }
}

.auction-item {
  font-size: 11px !important;
  position: relative;
  z-index: 1;
  text-transform: uppercase;

  &__btn {
    margin-top: 10px;
    margin-right: 10px;
    display: inline-flex;
    align-items: center;
    background: rgba(255,255,255, 0.1);
    border-radius: 10px;
    backdrop-filter: blur(15px);
    font-family: "Matrice";
    line-height: 1;
    font-size: 12px;

    &-text {
      padding: 17px 10px 15px 10px;
      position: relative;
      white-space: nowrap;
      &:after {
        content: "";
        position: absolute;
        right: -10px;
        top: 0;
        display: block;
        height: 100%;
        width: 1px;
        background: rgba(255,255,255, 0.1);
        margin: 0 10px;
      }
    }

    &-price {
      padding: 17px 10px 15px 10px;
      white-space: nowrap;
    }
  }

  &__owner {
    color: var(--white);
    margin-left: 5px;
    font-family: "Matrice-Regular" !important;
    text-transform: uppercase;
    line-height: 1;
    padding-right: 10px;
    word-break: break-all;
    font-size: 11px !important;

    span {
      font-family: "Matrice" !important;
      font-size: 11px !important;
    }

    &-wrapper {
      z-index: 2;
      padding: 5px;
      background: rgba(0, 0, 0, 0.3);
      backdrop-filter: blur(15px);
      display: flex;
      align-items: center;
      margin-right: auto;
      border-radius: 20px;
    }
  }

  .multiple {
    position: absolute;
    z-index: -1;
    inset: 0px;
    &:before,
    &:after {
      position: absolute;
      content: "";
      height: 40px;
      display: block;
      background: transparent none repeat scroll 0% 0%;
      border-radius: 16px;
      border-bottom: 2px solid rgba(0, 0, 0, 0.11);
    }
    &:before {
      left: 3px;
      right: 3px;
      bottom: -6px;
      z-index: -1;
    }
    &:after {
      left: 6px;
      right: 6px;
      bottom: -11px;
      z-index: -2;
    }
  }

  &:hover,
  &:focus {
    img {
      transform: scale(1.1);
    }
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 8px;
    transition: transform 0.3s cubic-bezier(0.23, 1, 0.32, 1);
  }

  .no-thumbnail {
    border-radius: 8px;
    background: #f9f9f9;
    border: 2px solid #dfdfdf;
    &:hover {
      border-color: rgba(#9b9b9b, 0.2);
    }
  }
  .title {
    white-space: nowrap;
    overflow-x: hidden;
    text-overflow: ellipsis;
    font-family: "Matrice" !important;
  }
  .token-collection-name {
    margin-bottom: 10px;
  }
  .item-content {
    width: calc(100% - 30px);
    .description {
      color: #9b9b9b;
      font-size: 12px;
      width: 100%;
      white-space: nowrap;
      overflow-x: hidden;
      text-overflow: ellipsis;
    }

    .grey-color {
      color: rgba(255, 255, 255, 0.6) !important;
      font-weight: 500;
    }
  }
}

.bid-history-item {
  align-items: center;
  .time-ago {
    font-size: 12px;
    color: rgba(0, 0, 0, 0.3);
  }
}

.history-item {
  display: flex;
  align-items: center;
  .detail-info {
    .grey-color {
      font-weight: 500;
    }

    @media screen and (max-width: 540px) {
      flex-wrap: wrap;
    }
  }
}

.nft-type-btn {
  cursor: pointer;
  border-bottom: 1px solid transparent;

  box-sizing: border-box;
  text-align: center;
  line-height: 30px;
  font-weight: 500;
  font-size: 1rem;
  padding: 6px 16px;
  min-width: 80px;
  margin-top: 5px;
  margin-bottom: 5px;
  color: grey;
  &:hover {
    color: var(--black) !important;
    border-bottom-color: var(--black) !important;
  }
  @media screen and (max-width: 1260px) {
    padding: 4px 12px;
  }
  @media screen and (max-width: 992px) {
    padding: 4px 8px;
  }
}
.nft-type-selected {
  background: var(--white);
  color: var(--black) !important;
  border-bottom-color: var(--black) !important;
}

.filter-sort-btn {
  background: var(--white);
  color: grey;
  border: 0;
  border-bottom: 1px solid transparent;
  font-weight: 500;
  font-size: 1rem;
  padding: 6px 16px;
  border-radius: 0 !important;
  outline: none;

  &:hover,
  &:focus,
  &:focus-within {
    color: var(--black) !important;
    background: transparent;
    box-shadow: none;
    outline: none;
    border-bottom-color: var(--black) !important;
  }

  @media screen and (max-width: 1200px) {
    padding: 4px 12px;
  }
  @media screen and (max-width: 992px) {
    padding: 4px 8px;
  }
}

.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
  color: var(--black);
  background-color: var(--white);
  border-color: var(--black);
  box-shadow: none;
}

/* Loading Spinner */
.loading-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100000;
}

.filter-btn {
  cursor: pointer;
  display: flex;
  align-items: center;
  border: 1px solid #00000026;
  box-shadow: none;
  background-color: transparent;
  color: var(--black);
  line-height: 40px;
  height: 40px;
  border-radius: 40px;
  padding: 0 20px;
  margin-right: 10px;
  margin-bottom: 10px;
  &.active {
    border-color: transparent;
  }
}

.item-list {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}

.no-data {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  margin: 0 auto;
  .no-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    h4 {
      font-family: "Matrice";
    }
    .btn {
      white-space: nowrap;
      width: auto;
    }
  }
}
.sticky-filters {
  position: sticky;
  top: 110px;
  .filter-header {
    display: flex;
    align-items: center;
    color: var(--black);
    .filter-reset {
      cursor: pointer;
      color: rgb(0, 102, 255);
      &:hover {
        color: var(--black);
      }
    }
  }
  .filter-buttons {
    display: flex;
    flex-wrap: wrap;
  }
}

.slide-container {
  width: 100%;
  .arrow-btn {
    position: sticky;
    min-width: 40px;
    height: 40px;
    border-radius: 40px;
    background-color: var(--white);
    color: #9b9b9b;
    font-size: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #9b9b9b;
    z-index: 3;
    &.left {
      top: 40%;
      left: 0;
      margin-left: -40px;
    }
    &.right {
      top: 40%;
      right: 0;
    }
  }
}

.top-user-item {
  border-radius: 10px;
  border: 2px solid #afafaf30;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.04);
  padding: 10px 20px;
  height: 100%;
  &:hover {
    border: 2px solid transparent;
    background-image: linear-gradient(
        rgba(255, 255, 255, 0),
        rgba(255, 255, 255, 0)
      ),
      linear-gradient(101deg, var(--black), var(--black));
    background-origin: border-box;
    background-clip: content-box, border-box;
    box-shadow: 1px 1000px 1px var(--white) inset;
  }
}

.clip-board {
  cursor: pointer;
  width: 155px;
  height: 30px;
  border-radius: 30px;
  line-height: 30px;
  padding: 0 10px;
  position: absolute;
  left: 50%;
  bottom: -70px;
  transform: translate(-50%, 100%);
  background-color: rgba(0, 0, 0, 0.12);
  color: var(--white);
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    background-color: #373737;
  }
}

.share-btn {
  width: 50px;
  height: 50px;
  border-radius: 60px !important;
  border: 2px solid #afafaf;
  &:hover {
    border-color: rgba(#afafaf, 0.8);
  }
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--black);
  .btn {
    padding: 0;
    outline: none;
    box-shadow: none;
    font-size: 26px;
    color: var(--black);
    &:after {
      display: none;
    }
    img {
      width: 25px;
      height: 25px;
    }
  }
  .custom-menu {
    border: none;
    padding: 20px;
    background-color: var(--white);
    margin-left: -120px !important;
    margin-top: 10px !important;
    width: 260px;
    border: 2px solid #00000029;
    color: var(--black);
    @media screen and (max-width: 608px) {
      margin-left: 110px !important;
    }
    @media screen and (max-width: 387px) {
      margin-left: -25px !important;
    }
    .share-links {
      display: flex;
      justify-content: space-between;
      .social-link-item {
        color: grey;
        text-decoration: none;
        .social-icon {
          width: 40px;
          height: 40px;
          border-radius: 40px;
          border: 1px solid grey;
          color: grey;
          font-size: 12px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 0 auto;
        }
      }
    }
  }
}

.btn-etc {
  font-size: 26px;
  letter-spacing: -10px;
  color: var(--black);
  text-decoration: none;
  outline: none;
  box-shadow: none;
  &:hover,
  &:focus {
    text-decoration: none;
    color: var(--black);
    box-shadow: none;
  }
}

.link-section {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  @media screen and (max-width: 640px) {
    padding: 0 10px;
  }
  .nav {
    flex-wrap: nowrap;
    overflow-x: auto;
    .nav-link {
      color: grey;
      white-space: nowrap;
      position: relative;
      padding-right: 10px;
      span {
        margin-left: 10px;
        padding: 0 5px;
        border-radius: 25px;
        background-color: grey;
        color: var(--white);
        line-height: 25px;
        font-size: 1rem;
        &.fill-count {
          background-color: var(--black);
        }
      }
    }
  }
  .nav-link.active {
    background-image: linear-gradient(
        to left,
        transparent 0%,
        cyan 0,
        transparent 0
      ),
      linear-gradient(101deg, var(--black), var(--black));
    background-size: 100% 2px;
    background-position: 0% 0%, 0% 100%;
    background-repeat: no-repeat;
    color: var(--black) !important;
  }
}

.breadcrumbs {
  color: var(--white);
  padding-left: 45px;
  text-shadow: 1px 1px 25px var(--black);
  @media screen and (max-width: 1400px) {
    padding-left: 10px;
  }
  @media screen and (max-width: 992px) {
    padding-left: 0;
    margin-left: -15px;
  }
  @media screen and (max-width: 640px) {
    margin-left: -5px;
  }
}

.item-columns {
  width: 100%;
  padding: 30px 20px !important;
  max-width: 1660px;
  margin: 0 auto;
  @media screen and (max-width: 1665px) {
    max-width: 1540px;
    .auction-item,
    .collection-item,
    .token-view {
      max-width: 270px;
      min-width: 270px;
    }
  }

  @media screen and (max-width: 1515px) {
    max-width: 1380px;
    .auction-item,
    .collection-item,
    .token-view {
      max-width: 300px;
      min-width: 300px;
    }
  }
  @media screen and (max-width: 1341px) {
    max-width: 1260px;
    .auction-item,
    .collection-item,
    .token-view {
      max-width: 270px;
      min-width: 270px;
    }
  }
  @media screen and (max-width: 1221px) {
    .auction-item,
    .collection-item,
    .token-view {
      max-width: 300px;
      min-width: 300px;
    }
  }
  @media screen and (max-width: 1017px) {
    .auction-item,
    .collection-item,
    .token-view {
      max-width: 270px;
      min-width: 270px;
    }
  }
  @media screen and (max-width: 927px) {
    .auction-item,
    .collection-item,
    .token-view {
      max-width: 300px;
      min-width: 300px;
    }
  }

  @media screen and (max-width: 693px) {
    .collection-item {
      margin-right: 0 !important;
      max-width: 100%;
      min-width: 100%;
    }
  }
}

.beta-testing-modal {
  .modal-dialog {
    max-width: 800px;
    margin-top: 80px;
    .modal-content {
      border-radius: 10px;
      border-color: rgba(0, 0, 0, 0.06);
      .modal-body {
        font-family: "Helvetica";
        div {
          color: rgba(0, 0, 0, 0.5);
          font-family: "Helvetica";
          // strong {
          //   font-family: "Helvetica-Bold";
          // }
        }
        .btn {
          width: 150px;
        }
      }
    }
  }
}

.likes {
  color: var(--black);
}

.input-range {
  &__slider {
    background: var(--black);
    border-color: var(--black);
  }
  &__track--active {
    background: var(--black);
  }
}
