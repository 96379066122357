.create-collectible-container {
  $switchIndex: 2rem;
  $mainSwitchVal: 1.5rem;
  .back-link {
    display: flex;
    align-items: flex-start;
    color: var(--black);
    opacity: 0.4;
    div {
      font-family: "Matrice";
    }
  }
  @media screen and (max-width: 554px) {
    padding: 30px 20px !important;
  }
  .row-reserve {
    @media screen and (max-width: 991px) {
      flex-wrap: wrap-reverse;
      .preview-area {
        padding-left: 15px;
      }
    }
  }
  .custom-switch {
    cursor: pointer !important;
    margin-right: 15px;
    margin-bottom: 10px;
    .custom-control-label::before {
      height: $mainSwitchVal !important;
      width: calc(#{$switchIndex} + 0.75rem) !important;
      border-radius: $mainSwitchVal * 2 !important;
    }

    .custom-control-label::after {
      width: calc(#{$mainSwitchVal} - 4px) !important;
      height: calc(#{$mainSwitchVal} - 4px) !important;
      border-radius: calc(#{$switchIndex} - (#{$mainSwitchVal} / 2)) !important;
    }

    .custom-control-input:checked ~ .custom-control-label::after {
      transform: translateX(calc(#{$mainSwitchVal} - 0.25rem)) !important;
    }
  }
  .preview-area {
    padding-left: 120px;
    .auction-item {
      max-width: 320px;
      @media screen and (max-width: 640px) {
        max-width: 100%;
      }
    }
  }
  .nft-type-btn {
    margin: 5px 0;
    @media screen and (max-width: 991px) {
      margin-right: 15px !important;
    }
  }
  h2 {
    @media screen and (max-width: 991px) {
      font-size: 22px;
    }
  }
  h3 {
    @media screen and (max-width: 991px) {
      font-size: 22px;
    }
  }
  .collection-types {
    overflow-x: auto;
    width: 100%;
  }
  .category-list {
    flex-wrap: wrap;
  }
  .form-control {
    border-radius: 20px;
    height: 54px;
  }
  .blockchain-list {
    display: flex;
    flex-wrap: wrap;
    .blockchain-item {
      padding: 10px;
      width: 160px;
      border: 2px solid var(--white);
      border-radius: 8px;
      text-align: center;
      color: var(--black);
      cursor: pointer;
      margin-right: 20px;
      position: relative;
      &:hover {
        border-color: rgba(black, 0.3);
      }
      .blockchain-icon {
        width: 40px;
        margin: 0 auto;
        margin-bottom: 5px;
      }
      &:first-child {
        .blockchain-icon {
          width: 20px;
        }
      }
      img {
        width: 100%;
        height: 50px;
      }
      .blockchain-name {
        font-size: 12px;
      }
      &.selected {
        border: 2px solid #136BFB;
        &:hover {
          border-color: rgba(#136BFB, 0.8);
        }
      }
      &.disabled {
        background-color: #f7f7f7;
        border: 2px solid #f7f7f7;
        opacity: 0.5;
        &:hover {
          border-color: rgba(#919191, 0.2);
        }
        img {
          opacity: 0.6;
        }
        .blockchain-name {
          opacity: 0.6;
        }
      }
      span {
        position: absolute;
        right: -2px;
        top: -5px;
        padding: 3px 10px;
        background-color: #dddddd;
        color: #181818;
        border-radius: 4px;
        font-size: 9px;
      }
    }
  }
}
