.userprofile-container {
  padding: 0px !important;
  display: flex;
  flex-direction: column;
  .section-1 {
    .intro {
      position: relative;
      width: 100%;
      height: 252px;
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      -webkit-background-size: cover;
      -moz-background-size: cover;
      -o-background-size: cover;
      padding: 15px 30px;
      box-shadow: -10px -1px 5px 0px rgba(255, 255, 255, 75%);
      -webkit-box-shadow: -10px -1px 5px 0px rgba(255, 255, 255, 75%);
      -moz-box-shadow: -10px -1px 5px 0px rgba(255, 255, 255, 75%);
      padding: 15px 30px;
      @media screen and (max-width: 786px) {
        padding: 15px 20px;
      }
      .avatar {
        width: 120px;
        height: 120px;
        border: 5px solid var(--white);
        position: absolute;
        left: 50%;
        bottom: 0px;
        transform: translate(-50%, 50%);
        object-fit: cover;
      }
      .verify-badge {
        width: 24px;
        height: 24px;
        position: absolute;
        bottom: -54px;
        left: 53%;
        @media screen and (max-width: 991px) {
          left: 55%;
        }
      }
    }
  }

  .section-2 {
    margin-top: 104px;
    .title {
      font-family: Montserrat;
      font-weight: 700;
      font-size: 40px;
      line-height: 50px;
      @media screen and (max-width: 576px) {
        font-size: 28px;
        line-height: 30px;
      }
    }
    .copy-icon {
      cursor: pointer;
      transition: ease-in-out all 0.5s;
      &:hover {
        transform: scale(1.1);
      }
      &.clicked {
        transform: rotate(180deg);
      }
    }
  }

  .section-3 {
    margin-top: 24px;
  }

  .section-4 {
    padding: 100px 0;
    @media screen and (max-width: 576px) {
      padding: 50px 0;
    }
    span {
      font-size: 16px;
    }

    .tokens-section,
    .collections-section,
    .auction-section,
    .royalties-section {
      margin-top: 55px;
      @media screen and (max-width: 654px) {
        margin-top: 30px;
      }
    }

    .token-view,
    .auction-item,
    .collection-item {
      margin-bottom: 1.5rem;
      @media screen and (max-width: 693px) {
        max-width: 100%;
        min-width: 100%;
        margin-right: 0 !important;
      }
    }

    .notice-view {
      margin-top: 80px;
      @media screen and (max-width: 768px) {
        margin-top: 40px;
      }
      .notice {
        max-width: 910px;
      }
      .btn-section {
        margin-top: 50px;
      }
    }

    .royal-section {
      margin-top: 74px;
      max-width: 500px;
      width: 100%;
      .border-bottom {
        border-bottom: 1px solid #afafaf !important;
        padding-bottom: 10px;
      }
    }

    .question-section {
      margin-top: 150px;
    }
  }
  .no-data {
    .no-item {
      max-width: 350px;
    }
  }
  .item-list {
    > .row {
      @media screen and (max-width: 768px) {
        flex-wrap: wrap-reverse;
      }
    }
    .activity-item {
      height: 100%;
    }
  }
}
