.token-view {
  .token-desc {
    font-family: Montserrat;
    font-weight: 400;
    font-size: 10px;
    opacity: 70%;
    display: flex;
    overflow-x: hidden;
    text-overflow: ellipsis;
  }
  .heart-icon{
    width: 12px;
    height: 12px;
    min-height: 12px;
  }
}
